<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="type" @change="loadUsers(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="2">个人</el-radio-button>
                <el-radio-button :label="3">企业</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
                </div>
            </div>
            <el-table :data="users.data" class="mt-2">
                <el-table-column prop="typeName" label="类型" width="100" />
                <el-table-column prop="phone" label="注册登录手机号" width="150" />
                <el-table-column prop="name" label="姓名/企业名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.name}}
                        </router-link>
                        <font-awesome-icon v-if="scope.row.authenticationStatus==3" icon="address-card" title="已认证"
                            class="text-success" />
                    </template>
                </el-table-column>
                <el-table-column prop="companyName" label="水发公司" sortable />
                <el-table-column prop="createTime" label="注册时间" sortable :formatter="$moment.sdtFormatter"
                    width="140" />
                <el-table-column label="操作" fixed="right" width="130">
                    <template #default="scope">
                        <el-button type="text" @click="resetUserPassword(scope.row.id)">重置密码</el-button>
                        <el-button type="text" class="text-danger" @click="disableUser(scope.row.id)">
                            禁用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
                :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
                @current-change="usersPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                type: null,
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                user: {},
            };
        },
        methods: {
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/RegisteredUser/GetRegisteredUsers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        type: this.type,
                        key: this.key,
                        pageIndex: this.usersPagination.currentPage - 1,
                        pageSize: this.usersPagination.pageSize,
                    }
                });
                this.users = response.data;
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            async resetUserPassword(id) {
                if (!confirm('确定要将密码重置为“123456”吗？')) {
                    return;
                }

                await this.$axios.post('/api/User/ResetUserPassword', { id: id });
                this.$message.success('密码重置成功。');
            },
            async disableUser(id) {
                if (!confirm('确定要禁用吗？')) {
                    return;
                }

                await this.$axios.post('/api/RegisteredUser/DisableRegisteredUser', { id: id });
                this.loadUsers();
            },
        },
        created() {
            this.loadUsers();
        },
    };
</script>